@import 'nib'

body.callback-showing
	overflow: hidden

.callback-backdrop
	position fixed
	overflow: hidden
	overflow-y scroll
	-webkit-overflow-scrolling: touch
	z-index: 999998
	width: 100%
	height: 100%
	top 0
	left 0
	background: rgba(0,0,0,.90)
	transition: opacity 1s ease-in-out
	pointer-events: none
	opacity: 0
	visibility: hidden
	transform scale(0)

	&.callback-active
		opacity: 1
		visibility: visible;
		pointer-events: auto
		transform scale(1)

.callback-wrap
	display: flex
	align-items: center
	justify-content: center
	position: absolute
	top 0
	left 0
	height: 100%
	width: 100%
	min-height: 500px

.callback-pop
	width: 300px
	background: #fff
	padding: 0 20px 25px
	border-radius: 4px
	font-size: 16px
	position: relative

	.callback-header
		position: relative
		margin 0 -20px 20px
		padding: 15px 20px
		font-weight: 700
		background: linear-gradient(to right,#35869a 0%,#4094a9 100%)
		color #fff
		border-radius: 4px 4px 0 0

	.callback-close
		width: 46px
		height: 46px
		transform scale(.4)
		transform-origin: center
		position: absolute;
		top 50%
		right 0
		margin-top -23px
		background-image: url("https://asset.healthinsurancecomparison.com.au/wp-content/uploads/2020/09/03010804/btn-close-green.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		overflow: hidden
		text-indent 10000px

	label
		display: block
		margin: 0 0 5px
		font-size: 14px

	input
		width: 100%
		border-radius: 4px
		font-size: 15px
		margin:  0 0 15px
		border 1px solid #c2c2c2
		outline 0
		padding: 10px 15px
		line-height: normal


	button
		background: #5cb85c
		line-height: normal
		color #fff
		border 0
		outline 0
		display: block
		width: 100%
		border-radius: 6px
		height: 50px
		margin: 10px auto 5px
		box-shadow 0 6px 0 0 #4cae4c
		position: relative
		top 0

		transition: all .5s ease-in-out;

		&:focus
			top 6px
			box-shadow 0 0 0 0 darken(#4cae4c, 15%)
